









































import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import {ArkStatsBackendClient, StructureDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import AsyncComputedProp from 'vue-async-computed-decorator';
import moment from 'moment';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class Structures extends Vue {
    private loading = true;

    private selectedTypes: string[] = [];
    private selectedServers: number[] = [];
    private selectedOwners: string[] = [];
    private lastInAllyRangeBefore: string | null = null;
    private selectedMinCount = 0;
    private initialized = false;

    private headers() {
        const columns = [];
        columns.push({text: 'Server', value: 'serverId'});
        columns.push({text: 'Structure Type', value: 'type'});
        columns.push({text: 'Owner', value: 'ownerName'});
        columns.push({text: 'Count', value: 'count'});
        if (!this.$vuetify.breakpoint.smAndDown) {
            columns.push({text: 'Last in ally range', value: 'lastInAllyRange'});
        }
        return columns;
    }

    public clickedOnStructure(value: StructureDto): void {
        if (value && value.id) {
            const routeData = this.$router.resolve({name: 'StructureById', params: {id: value.id}});
            window.open(routeData.href, '_blank');
        }
    }

    public created(): void {
        const queryParams = this.$route.query;

        this.selectedTypes = queryParams.type
            ? Array.isArray(queryParams.type) ? queryParams.type as string[] : [String(queryParams.type)]
            : [];

        this.selectedServers = queryParams.server
            ? Array.isArray(queryParams.server) ? queryParams.server.map(s => Number(s)) : [Number(queryParams.server)]
            : [];

        this.selectedOwners = queryParams.owner
            ? Array.isArray(queryParams.owner) ? queryParams.owner as string[] : [queryParams.owner]
            : [];

        this.selectedMinCount = Number(queryParams.minCount ? queryParams.minCount : 0);

        if (queryParams.lastInAllyRangeBefore) {
            this.lastInAllyRangeBefore = String(queryParams.lastInAllyRangeBefore);
        }

        this.initialized = true;
    }

    @AsyncComputedProp()
    public async structures(): Promise<StructureDto[] | null> {
        if (!this.initialized) {
            return [];
        }
        this.loading = true;
        const structures = await ArkStatsBackendClient.getStructures({
            orderBy: '-count',
            fields: ['serverId', 'ownerName', 'type', 'lastInAllyRange', 'count'],
            filter: this.filter({})
        });
        this.loading = false;
        return structures.data;
    }

    @AsyncComputedProp()
    public async types(): Promise<string[]> {
        if (!this.initialized) {
            return [];
        }
        return this.addSelectedValues(await ArkStatsBackendClient.getStructureValues(
            'type',
            this.filter({type: false})
        ), this.selectedTypes);

    }

    @AsyncComputedProp()
    public async owners(): Promise<string[]> {
        if (!this.initialized) {
            return [];
        }
        return this.addSelectedValues(
            await ArkStatsBackendClient.getStructureValues(
                'ownerName',
                this.filter({owner: false})),
            this.selectedOwners);
    }

    @AsyncComputedProp()
    public async servers(): Promise<number[]> {
        if (!this.initialized) {
            return [];
        }
        return this.addSelectedValues(
            await ArkStatsBackendClient.getStructureValues(
                'serverId',
                this.filter({server: false})),
            this.selectedServers);
    }

    get url(): string {
        const queryParams: string[] = [];
        if (this.selectedTypes && this.selectedTypes.length) {
            queryParams.push(this.selectedTypes.map(t => 'type=' + t).join('&'));
        }
        if (this.selectedServers && this.selectedServers.length) {
            queryParams.push(this.selectedServers.map(t => 'server=' + t).join('&'));
        }
        if (this.selectedOwners && this.selectedOwners.length) {
            queryParams.push(this.selectedOwners.map(t => 'owner=' + t).join('&'));
        }
        if (this.selectedMinCount) {
            queryParams.push('minCount=' + this.selectedMinCount);
        }
        if (this.lastInAllyRangeBefore) {
            queryParams.push('lastInAllyRangeBefore=' + this.lastInAllyRangeBefore);
        }
        const url = this.$route.path + '?' + queryParams.join('&');
        history.pushState({}, 'Title', url);
        return url;
    }

    public filter({type = true, owner = true, server = true, lastInAllyRange = true, count = true}): string {
        const filters: string[] = [];

        if (type && this.selectedTypes?.length) {
            filters.push('type=in=("' + this.selectedTypes.map(this.escape).join('","') + '")');
        }
        if (owner && this.selectedOwners?.length) {
            filters.push('ownerName=in=("' + this.selectedOwners.map(this.escape).join('","') + '")');
        }
        if (server && this.selectedServers?.length) {
            filters.push('serverId=in=(' + this.selectedServers.join(',') + ')');
        }
        if (count && this.selectedMinCount) {
            filters.push('count=ge=' + this.selectedMinCount);
        }
        if (lastInAllyRange && this.lastInAllyRangeBefore) {
            filters.push('lastInAllyRange=le=' + moment(this.lastInAllyRangeBefore).toISOString());
        }
        return filters.join(';');
    }

    escape(value: string): string {
        if (value.replaceAll) {
            return value
                .replaceAll(`\\`, `\\\\`)
                .replaceAll(`'`, `\\'`)
                .replaceAll(`"`, `\\"`);
        } else {
            return value;
        }
    }

    private addSelectedValues<T>(values: T[], selected: T[]): T[] {
        if (!selected || !selected.length) {
            return values;
        }
        selected.forEach(type => {
            if (values.indexOf(type) === -1) {
                values.push(type);
            }
        });
        if (typeof values[0] === 'number') {
            values.sort((a, b) => Number(a) - Number(b));
        } else {
            values.sort();
        }
        return values;
    }
}
